import React from "react";
import PropTypes from "prop-types";
import { property, isEqual } from "lodash";
import { combineLatest, ReplaySubject } from "rxjs";
import { UserTenantProps } from "cp-client-auth!sofe";
import { catchAsyncStacktrace } from "auto-trace";
import { primaryNavHeightObs } from "./primary-navbar.js";

@UserTenantProps()
export default class NavContent extends React.Component {
  static propTypes = {
    hasTopnavSecondary: PropTypes.bool,
    contactMenuPossible: PropTypes.bool,
    clientMenuPossible: PropTypes.bool,
  };

  static defaultProps = {
    hasTopnavSecondary: false,
    contactMenuPossible: false,
    clientMenuPossible: false,
  };

  constructor() {
    super();
    this.state = {
      navContentTop: 0,
    };

    this.subscription = null;
    this.mounted = false;
    this.secondaryNavHeightSubject = null;
  }

  componentDidMount() {
    this.mounted = true;
    this.calculateHeight(this.props);
  }

  componentDidUpdate(prevProps) {
    if (
      this.propsChanged(
        prevProps,
        "hasTopnavSecondary",
        "contactMenuPossible",
        "clientMenuPossible",
        "topNavSecondaryHeight"
      )
    ) {
      this.calculateHeight(this.props);
    }
  }

  /**
   * Calculate the height of navcontent based upon the primary and secondary nav heights
   */
  calculateHeight({
    hasTopnavSecondary,
    contactMenuPossible,
    clientMenuPossible,
    topNavSecondaryHeight,
    loggedInUser,
  }) {
    this.cleanupObservables();

    const SECONDARY_NAV_HEIGHT = 56;

    // A promise that returns an observable for the height of the secondary navbar (including client menu)
    let secondaryNavHeightObservablePromise;
    // If we create our own observable/subject for the height, we'll need to clean it up when we're done
    let secondaryNavHeightSubject;

    if (hasTopnavSecondary) {
      secondaryNavHeightSubject = new ReplaySubject(1);
      secondaryNavHeightSubject.next(
        topNavSecondaryHeight || SECONDARY_NAV_HEIGHT
      );
      secondaryNavHeightObservablePromise = Promise.resolve(
        secondaryNavHeightSubject
      );
    } else if (contactMenuPossible || clientMenuPossible) {
      if (loggedInUser.role === "Client") {
        // Clients do not see the client menu
        secondaryNavHeightSubject = new ReplaySubject(1);
        secondaryNavHeightSubject.next(0);
        secondaryNavHeightObservablePromise = Promise.resolve(
          secondaryNavHeightSubject
        );
      } else {
        secondaryNavHeightObservablePromise = SystemJS.import(
          "client-menu!sofe"
        ).then(property("clientMenuHeightObs"));
      }
    } else {
      // There is no secondary nav
      secondaryNavHeightSubject = new ReplaySubject(1);
      secondaryNavHeightSubject.next(0);
      secondaryNavHeightObservablePromise = Promise.resolve(
        secondaryNavHeightSubject
      );
    }

    this.secondaryNavHeightSubject = secondaryNavHeightSubject;

    secondaryNavHeightObservablePromise
      .then((secondaryNavHeightObservable) => {
        // Now combine the two observables together.
        const subscription = combineLatest(
          primaryNavHeightObs,
          secondaryNavHeightObservable
        ).subscribe((values) => {
          const [primaryNavHeight = 0, secondaryNavHeight = 0] = values;

          if (this.mounted) {
            this.setState({
              navContentTop: primaryNavHeight + secondaryNavHeight,
            });
          }
        });

        this.subscription = subscription;
      })
      .catch(catchAsyncStacktrace());
  }

  componentWillUnmount() {
    this.mounted = false;
    this.cleanupObservables();
  }

  render() {
    const {
      className = "",
      children,
      style = {},
      useNavContentClass = true,
      topStyleAttribute = "marginTop",
    } = this.props;

    const mergedStyles = {
      ...style,
      [topStyleAttribute]:
        String(this.state.navContentTop + (parseInt(style.top, 10) || 0)) +
        "px",
    };

    let childrenWithTop;
    if (this.props.includeContentTop) {
      childrenWithTop = React.Children.map(children, (child) =>
        React.cloneElement(child, {
          ...child.props,
          contentTop: this.state.navContentTop + (style.top || 0),
        })
      );
    }

    return (
      <div
        className={`${className} ${
          useNavContentClass ? "cps-nav-content-new" : ""
        }`}
        style={mergedStyles}
        data-testid="nav-content"
      >
        {childrenWithTop || children}
      </div>
    );
  }

  cleanupObservables = () => {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.state.secondaryNavHeightSubject) {
      this.state.secondaryNavHeightSubject.complete();
    }
  };

  propsChanged = (prevProps, ...propNames) =>
    propNames.some(
      (propName) => !isEqual(prevProps[propName], this.props[propName])
    );
}
